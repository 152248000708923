import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    children: [
      // 首页
      {
        path: '/index',
        name: 'index',
        component: () => import('../views/index/index.vue'),
      },
      // 核心技术
      {
        path: '/iotMiddleware',
        name: 'iotMiddleware',
        component: () => import('../views/coreTechnology/iotMiddleware.vue'),
      },
      {
        path: '/wirelessDataAcquisition',
        name: 'wirelessDataAcquisition',
        component: () => import('../views/coreTechnology/wirelessDataAcquisition.vue'),
      },
      {
        path: '/webSCADA',
        name: 'webSCADA',
        component: () => import('../views/coreTechnology/webSCADA.vue'),
      },
      {
        path: '/gisDigitalTwin',
        name: 'gisDigitalTwin',
        component: () => import('../views/coreTechnology/gisDigitalTwin.vue'),
      },
      // 运维系统 
      {
        path: '/operation',
        name: 'operation',
        component: () => import('../views/operation/index.vue'),
      },
      // 数字孪生 
      {
        path: '/digitalTwins',
        name: 'digitalTwins',
        component: () => import('../views/digitalTwins/index.vue'),
      },
      // 组态模块
      {
        path: '/configuration',
        name: 'configuration',
        component: () => import('../views/configuration/index.vue'),
      },
      // 产品详情
      {
        path: '/product',
        name: 'product',
        component: () => import('../views/product/index.vue'),
      },
      // 关于迪倍特
      {
        path: '/about',
        name: 'about',
        component: () => import('../views/about/index.vue'),
      },
      // 联系我们
      {
        path: '/contactUs',
        name: 'contactUs',
        component: () => import('../views/contactUs/index.vue'),
      },
      // 水务系统
      {
        path: '/waterSystem',
        name: 'waterSystem',
        component: () => import('../views/systemIntegration/urbanAndRural/waterSystem.vue'),
      },
      // 控制系统
      {
        path: '/controlSystem',
        name: 'controlSystem',
        component: () => import('../views/systemIntegration/urbanAndRural/controlSystem.vue'),
      },
      // 运维系统
      {
        path: '/operatingSystem',
        name: 'operatingSystem',
        component: () => import('../views/systemIntegration/urbanAndRural/operatingSystem.vue'),
      },
      // 采集系统
      {
        path: '/acquisitionSystem',
        name: 'acquisitionSystem',
        component: () => import('../views/systemIntegration/city/acquisitionSystem.vue'),
      },
      // 报表系统
      {
        path: '/reportingSystem',
        name: 'reportingSystem',
        component: () => import('../views/systemIntegration/city/reportingSystem.vue'),
      },
      // 水务营收系统
      {
        path: '/waterRenvueSystem',
        name: 'waterRenvueSystem',
        component: () => import('../views/systemIntegration/city/waterRenvueSystem.vue'),
      },
      // 水库在线监测系统
      {
        path: '/reservoirMonitoringSystem',
        name: 'reservoirMonitoringSystem',
        component: () => import('../views/systemIntegration/waterConservancy/reservoirMonitoringSystem.vue'),
      },
      // 监测管理系统
      {
        path: '/managementSystem',
        name: 'managementSystem',
        component: () => import('../views/systemIntegration/waterConservancy/managementSystem.vue'),
      },
      // 灌溉系统
      {
        path: '/irrigationSystems',
        name: 'irrigationSystems',
        component: () => import('../views/systemIntegration/waterConservancy/irrigationSystems.vue'),
      },
    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path == '/'||to.path == '/home') {
    next('/index')
  } else {
    next()
  }
})

export default router
